import React from 'react';
import { Bot, Command, Twitter, Trophy, Star } from 'lucide-react';

const RaidlyLanding = () => {
  const isDevMode = true; // Toggle this to enable/disable buttons

  const LinkButton = ({ children, className }) => {
    if (isDevMode) {
      return (
        <span className={`${className} opacity-50 cursor-not-allowed`}>
          {children}
        </span>
      );
    }
    return (
      <a 
        href="https://t.me/raidlybot"
        target="_blank"
        rel="noopener noreferrer"
        className={`${className} cursor-pointer`}
      >
        {children}
      </a>
    );
  };

  return (
    <div className="min-h-screen bg-zinc-950 text-gray-100">
      <div className="fixed inset-0" style={{
        backgroundImage: `
          linear-gradient(90deg, rgba(255, 0, 0, 0.03) 1px, transparent 1px),
          linear-gradient(180deg, rgba(255, 0, 0, 0.03) 1px, transparent 1px)
        `,
        backgroundSize: '40px 40px'
      }}/>

      <div className="relative">
        <header className="border-b border-red-500/10 backdrop-blur-sm bg-black/40">
          <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="relative">
                <div className="absolute inset-0 bg-red-500 blur-md opacity-20"/>
                <Bot className="w-8 h-8 text-red-500 relative"/>
              </div>
              <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-rose-500">
                Raidly
              </span>
            </div>
            <LinkButton className="px-6 py-2 bg-gradient-to-r from-red-500/20 to-rose-500/20 border border-red-500/20 rounded-lg hover:from-red-500/30 hover:to-rose-500/30 transition-colors">
              {isDevMode ? 'Disabled in Dev Mode' : 'Launch Raidly'}
            </LinkButton>
          </nav>
        </header>

        <div className="container mx-auto px-6 pt-32 pb-20">
          <div className="max-w-2xl mx-auto text-center">
            <div className="inline-block mb-6">
              <div className="px-4 py-1 border border-red-500/20 rounded-full text-sm text-red-400 bg-red-500/5 backdrop-blur-sm">
                Web3 Community Management
              </div>
            </div>
            <h1 className="text-7xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-red-500 via-rose-500 to-purple-500">
              The #1 Raid Rewards Bot
            </h1>
            <p className="text-xl text-gray-400 mb-12">
              Give new rewards to those who support your community
            </p>
            <LinkButton className="group inline-flex px-8 py-4 bg-gradient-to-r from-red-500 to-rose-600 rounded-lg relative overflow-hidden hover:from-red-600 hover:to-rose-700 transition-colors">
              <span className="relative flex items-center justify-center space-x-2">
                <Command className="w-5 h-5" />
                <span>{isDevMode ? 'Disabled in Dev Mode' : 'Launch Raidly'}</span>
              </span>
            </LinkButton>
          </div>
        </div>

        <div className="container mx-auto px-6 py-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: Twitter, title: "Tweet Tracking", desc: "Track community engagement with real-time verification and analytics" },
              { icon: Trophy, title: "Reward System", desc: "Automated distribution of points and rewards based on engagement" },
              { icon: Star, title: "Leaderboards", desc: "Competitive rankings to drive community participation" }
            ].map((feature, i) => (
              <div key={i} className="group relative">
                <div className="absolute inset-0 bg-gradient-to-r from-red-500/5 to-rose-500/5 rounded-xl blur-lg group-hover:blur-xl transition-all"/>
                <div className="relative p-8 backdrop-blur-sm bg-black/40 rounded-xl border border-red-500/10 hover:border-red-500/20 transition-all">
                  <div className="flex items-center space-x-4 mb-4">
                    <feature.icon className="w-6 h-6 text-red-500" />
                    <h3 className="text-xl font-semibold">{feature.title}</h3>
                  </div>
                  <p className="text-gray-400">{feature.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container mx-auto px-6 py-20 border-t border-red-500/10">
          <div className="grid grid-cols-3 gap-8 text-center">
            {[
              { value: "50K+", label: "Active Users" },
              { value: "1M+", label: "Tweets Tracked" },
              { value: "100K+", label: "Rewards Given" }
            ].map((stat, i) => (
              <div key={i} className="backdrop-blur-sm bg-black/20 rounded-lg p-6">
                <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-red-400 to-rose-500">
                  {stat.value}
                </div>
                <div className="text-gray-500 mt-1">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaidlyLanding;