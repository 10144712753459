import React, { useEffect, useState } from 'react';
import { Bot, Check, Copy, CheckCircle } from 'lucide-react';

const SuccessPage = () => {
  const [copied, setCopied] = useState(false);
  const [verifyCommand, setVerifyCommand] = useState('');

  useEffect(() => {
    // Get the OAuth tokens from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const oauth_token = urlParams.get('oauth_token');
    const oauth_verifier = urlParams.get('oauth_verifier');

    if (oauth_token && oauth_verifier) {
      // Combine tokens and convert to base64
      const combinedTokens = `${oauth_token}_${oauth_verifier}`;
      const base64Tokens = btoa(combinedTokens);
      setVerifyCommand(`/link ${base64Tokens}`);
    }
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(verifyCommand);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="min-h-screen bg-zinc-950 text-gray-100 flex items-center justify-center">
      <div className="fixed inset-0" style={{
        backgroundImage: `
          linear-gradient(90deg, rgba(255, 0, 0, 0.03) 1px, transparent 1px),
          linear-gradient(180deg, rgba(255, 0, 0, 0.03) 1px, transparent 1px)
        `,
        backgroundSize: '40px 40px'
      }}/>
      
      <div className="text-center relative">
        <div className="mb-6 flex justify-center">
          <div className="relative">
            <div className="absolute inset-0 bg-green-500 blur-xl opacity-20 animate-pulse"/>
            <Check className="w-16 h-16 text-green-500"/>
          </div>
        </div>
        <h1 className="text-4xl font-bold mb-4">Successfully Connected!</h1>
        <p className="text-gray-400 mb-8">Copy the command below and paste it in Telegram:</p>
        
        <div className="max-w-md mx-auto mb-8">
          <div 
            className="bg-zinc-900 p-4 rounded-lg border border-red-500/20 flex items-center justify-between group cursor-pointer relative"
            onClick={handleCopy}
          >
            <input
              type="text"
              value={verifyCommand}
              readOnly
              className="bg-transparent text-gray-300 w-full mr-4 cursor-pointer outline-none"
            />
            {copied ? (
              <CheckCircle className="w-5 h-5 text-green-500" />
            ) : (
              <Copy className="w-5 h-5 text-gray-500 group-hover:text-white transition-colors" />
            )}
          </div>
        </div>

        <div className="flex items-center justify-center space-x-2 text-red-500">
          <Bot className="w-5 h-5 animate-bounce"/>
          <span>Raidly Bot</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;